import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ReactComponent as AssistIcon } from '../../../assets/icons/assist.svg';
import { ReactComponent as BallIcon } from '../../../assets/icons/ball.svg';
import CButton from "../../../components/Button";
import { CText } from "../../../components/Text";
import { SearchBar } from '../../../components/TextInput';
import ProfilePicture from "../../../components/ProfilePicture/index";
import CSelect from "../../../components/Select";
import { useStyles } from './Highlights.styles.js';
import { matchTimes, searchPlayers, getTeamPlayers } from './utils.js';

const GoalDescriptionModal = ({goalDescription, goalNumber}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [selectedScorer, setSelectedScorer] = useState(null);
  const [selectedAssister, setSelectedAssister] = useState(null);
  const [selectedGameTime, setSelectedGameTime] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [listPlayers, setListPlayers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {  
    const fetchData = async () => {
      const players = await getTeamPlayers(userStored);
      setAllPlayers(players)
      setListPlayers(players.slice(0, 6))
    }
  
    fetchData()
  }, [])

  const selectedTimeCb = (data) => {
    setSelectedGameTime(data)
  }

  const onChangeSearch = async(data) => {
    setSearchValue(data);
    const players = await searchPlayers(allPlayers, data);
    setListPlayers([]); // Don't remove
    setListPlayers(players);
  }

  const selectScorer = (player) => {
    setSelectedScorer(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const selectAssister = (player) => {
    setSelectedAssister(player);
    setSearchValue('');
    setListPlayers(allPlayers.slice(0, 6))
  };

  const submit = () => {
    const data = {
      scorer: {
        id: selectedScorer.user.id,
        first_name: selectedScorer.user.first_name,
        last_name: selectedScorer.user.last_name
      },
      assister: {
        id: selectedAssister.user.id,
        first_name: selectedAssister.user.first_name,
        last_name: selectedAssister.user.last_name
      },
      own_goal: false,
      time: selectedGameTime.code
    };

    goalDescription(data);
  }
  
  let CONTENT;
  if(selectedScorer === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.0.inputs.0.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectScorer(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else if(selectedAssister === null) {
    CONTENT =
    <div className={classes.modalSearch}>
      <div className={classes.modalSearchBarWrapper}>
        <SearchBar
          placeholder={`${t('after_game.2.modals.1.inputs.0.label')}`}
          value={searchValue}
          inputCallback={onChangeSearch}
        />
      </div>
      <ul className={classes.modalSearchResults}>
        {listPlayers?.map((player, index) => (
          <li key={index}>
            <div className={classes.modalSearchResult} onClick={() => selectAssister(player)}>
              <ProfilePicture className={classes.modalPlayerPhoto} src={player.user.photo_path} />
              <CText level="p2" fontWeight={500} color="light" label={`${player.user.first_name} ${player.user.last_name}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  } else {
    CONTENT =
    <>
      <div className={classes.modalTitle}>
        <CText level="h2" fontWeight={700} color="primary">{t('after_game.2.modals.2.title')}{goalNumber}</CText>
      </div>
      <div className={classes.modalInfo}>
        <div>
          <div className={classes.goalsScorerZone}>
            <span className={classes.icon}>
              <BallIcon />
            </span>
            <CText level="p1" color="light">
              {selectedScorer.user.first_name} {selectedScorer.user.last_name}
            </CText>
          </div>
          <div className={classes.goalsAssiterZone2}>
            <span className={classes.icon}>
              <AssistIcon />
            </span>
            <CText level="p1" color="light">
              {selectedAssister.user.first_name} {selectedAssister.user.last_name}
            </CText>
          </div>
        </div>
        <div className={classes.selector}>
          <CSelect
            label={t('common.game_time')}
            selectCallback={selectedTimeCb}
            data={matchTimes}
            value={selectedGameTime}
          />
        </div>
      </div>

      <CButton
        className={classes.modalValidationBtn}
        label={t('button.confirm')}
        height={38}
        type="contained"
        onClick={() => submit()}
      />
    </>
  }

  return (
    <div className={classes.modalContainer}>
      {CONTENT}
    </div>
  );
}

export default GoalDescriptionModal;