import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { Auth, API, Storage } from 'aws-amplify';
import { getSession } from "../../utils/auth";
import Tooltip from '@mui/material/Tooltip';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import moment from 'moment';
import CButton, { PickMediaBtn } from "../../components/Button";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import CTable from "../../components/Table/Table";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPhotoPath } from '../../store/reducers/user';
import ProfilePicture from "../../components/ProfilePicture/index";
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as BallIcon } from '../../assets/icons/ball.svg';
import { ReactComponent as AssistIcon } from '../../assets/icons/assist.svg';
import Dropdown from "../../components/DropdownSelector/Dropdown";
import { getCurrentSeason } from "../../utils/season";
import axios from 'axios';
import UserCard from "../../components/Photos/UserCard.js";
import StatCard from "./StatCard.js";
import './Profile.css';
import './ProfilePicture.css';
import './StatCard.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

const calculatePlayerResults = (matches) => {
  let wins = 0;
  let losses = 0;
  let draws = 0;
  const currentDate = new Date();

  const validMatches = matches.filter((data) => {
    const matchDate = new Date(data.match.date);
    return matchDate <= currentDate;
  });

  validMatches.forEach((data) => {
    if (data.match.home_team_id) {
      if (data.match.home_team_goals !== null && data.match.away_team_goals !== null) {
        if (data.match.home_team_goals > data.match.away_team_goals) {
          wins++;
        } else if (data.match.home_team_goals === data.match.away_team_goals) {
          draws++;
        } else {
          losses++;
        }
      }
    } else if (data.match.away_team_id) {
      if (data.match.home_team_goals !== null && data.match.away_team_goals !== null) {
        if (data.match.away_team_goals > data.match.home_team_goals) {
          wins++;
        } else if (data.match.away_team_goals === data.match.home_team_goals) {
          draws++;
        } else {
          losses++;
        }
      }
    }
  });

  return { wins, losses, draws };
};

//For coach
// Attention retirer tous les matchs qui ont une date future

const calculateResults = (data) => {
  let wins = 0;
  let losses = 0;
  let draws = 0;

  data.forEach((data) => {
    if (data.home_team_id) {
      if (data.home_team_goals !== null && data.away_team_goals !== null) {
        if (data.home_team_goals > data.away_team_goals) {
          wins++;
        } else if (data.home_team_goals === data.away_team_goals) {
          draws++;
        } else {
          losses++;
        }
      }
    } else if (data.away_team_id) {
      if (data.home_team_goals !== null && data.away_team_goals !== null) {
        if (data.away_team_goals > data.home_team_goals) {
          wins++;
        } else if (data.away_team_goals === data.home_team_goals) {
          draws++;
        } else {
          losses++;
        }
      }
    }
  });

  return { wins, losses, draws };
};

const fetchSeasons = async (userId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_REST_API_BASE_URL}/users/v1/users/${userId}/seasons`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
  );
  return response?.data;
};

const getUserTeamsBySeason = async (userId, season) => {
  const response = await axios.get(
    `${process.env.REACT_APP_REST_API_BASE_URL}/users/v1/users/${userId}/teams?season=${season}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
  );
  return response?.data;
};

const ProfilePhotoModal = ({ modalOpenCallback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userStored = useSelector(state => state?.user);

  const [previewFile, setPreviewFile] = useState();
  const [fileData, setFileData] = useState();
  const [fileStatus, setFileStatus] = useState(false);


  const pickFile = (data) => {
    setPreviewFile(URL.createObjectURL(data))
    setFileData(data)
  }

  const removeFile = (data) => {
    setPreviewFile()
    setFileData()
  }

  const uploadFile = async () => {
    // [EVOL] Le nom du fichier ainsi que la création/update de l'url en bdd doit etre fait dans une lambda
    try {
      const userId = userStored?.info?.id;

      const fileType = fileData.type
      let fileExtension

      switch (fileType) {
        case 'image/png':
          fileExtension = "png"
          break;
        case 'image/jpeg':
          fileExtension = "jpeg"
          break;
        case 'image/svg+xml':
          fileExtension = "svg"
          break;
        case 'image/webp':
          fileExtension = "webp"
          break;
        default:
          throw new Error("Invalid file format.");
      }

      const filePath = `users/${userId}/${Date.now()}.${fileExtension}`

      await getSession()

      await Storage.put(filePath, fileData, {
        contentType: fileData.type,
      });
      const data = {
        id: userId,
        photo_path: `${filePath}`
      }
      await API.graphql({ query: mutations.updateUser, variables: {input: data}, authMode: 'AMAZON_COGNITO_USER_POOLS'})

      dispatch(setPhotoPath({ "photoPath": filePath }))
      modalOpenCallback(false)
    } catch(err) {
      //console.log(err)
    }
  }

  let CONTENT;
  if(previewFile) {
    CONTENT =
    <div className="profile-picture-select">
      <ProfilePicture className="profile-picture" src={previewFile} updatePhoto={true} />
      <div className="profile-picture-btn-zone">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CButton
              className="profile-picture-btn-inline"
              onClick={() => removeFile()}
              label={t('profile.modal_picture.buttons.1.label')}
              height={38} color={COLOR_PINK}
              type="contained"
            />
          </Grid>
          <Grid item xs={6}>
            <CButton
              className="profile-picture-btn-inline"
              label={t('profile.modal_picture.buttons.2.label')}
              onClick={() => uploadFile()}
              height={38}
              color={COLOR_PRIMARY}
              type="contained"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  } else {
    CONTENT =
    <div className="profile-picture-select">
      <div className="profile-picture-btn-zone">
        <PickMediaBtn
          label={t('profile.modal_picture.buttons.0.label')}
          fileCb={(data) => pickFile(data)}
          height={38}
          color={COLOR_PRIMARY}
          type="contained"
        />
      </div>
    </div>
  }

  return (
    <div className="profile-picture-container">
      <CText level="h2" fontWeight={700} className="profile-picture-title" color="primary" label={`${t('profile.modal_picture.title')}`} />
      {CONTENT}
    </div>
  )
}

const Profile = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [userType, setUserType] = useState();
  const [seasonResume, setSeasonResume] = useState({matches: 0, wins: 0, draws: 0, losses: 0});
  const [loading, setLoading] = useState(true);
  const [clubHistorical, setClubHistorical] = useState([]);
  const [userSeasons, setUserSeasons] = useState([]);
  const [userSeasonSelected, setUserSeasonSelected] = useState("");
  const [userTeams, setUserTeams] = useState([]);
  const [userTeamSelectedIndex, setUserTeamSelectedIndex] = useState(0);
  const [currentTeams, setCurrentTeams] = useState([]);
  
  // Get User
  const userStored = useSelector((state) => state?.user);
  const isAuthenticated = useSelector((state) => state?.user.isAuthenticated);

  const getTeams = async (currentSeason) => {
    try {
      let teamsList = [];
      let teamsListOrdered = [];
  
      if (userStored.groups.includes("Players") || userStored.groups.includes("Coaches")) {
        const userId = userStored.info.id;
      
        const teamFilter = {
          season: { eq: currentSeason },
          invite_status: { eq: 1 }
        };
      
        let getUserTeamsWithConversation = await API.graphql({
          query: queries.getUserTeamsWithConversation,
          variables: {
            id: userId,
            teamFilter: teamFilter
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        getUserTeamsWithConversation = getUserTeamsWithConversation?.data?.getUser?.teams.items;

        teamsList = getUserTeamsWithConversation?.map((team) => ({
          id: team.team.id,
          name: team.team.name,
          club: {
            id: team.team.club.id,
            names: team.team.club.names,
            logo: team.team.club.logo,
          }
        })) || [];
      
        teamsListOrdered = teamsList.sort((a, b) => {
          const clubNameA = JSON.parse(a.club.names[0]).name.toLowerCase();
          const clubNameB = JSON.parse(b.club.names[0]).name.toLowerCase();
        
          // Compare club names
          if (clubNameA < clubNameB) return -1;
          if (clubNameA > clubNameB) return 1;
        
          // If club names are the same, sort by team names
          const teamNameA = a.name.toLowerCase();
          const teamNameB = b.name.toLowerCase();
        
          // Extract numbers from team names
          const teamNumberA = parseInt(teamNameA.replace(/\D/g, ''), 10) || 0;
          const teamNumberB = parseInt(teamNameB.replace(/\D/g, ''), 10) || 0;
        
          // Compare the numbers extracted from the team names
          if (teamNumberA < teamNumberB) return -1;
          if (teamNumberA > teamNumberB) return 1;
        
          // If team numbers are the same or absent, sort by team name alphabetically
          if (teamNameA < teamNameB) return -1;
          if (teamNameA > teamNameB) return 1;
        
          return 0;
        });
      }
      
      setCurrentTeams(teamsListOrdered);
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentSeason = await getCurrentSeason();

      /*****   Récupération des saisons et des équipes pour les stats du user  *****/
      /*const userSeasons = await fetchSeasons(userId);
      const formattedSeasons = userSeasons.map(season => ({
        code: season,
        label: season
      }));
      setUserSeasons(formattedSeasons);
      setUserSeasonSelected(formattedSeasons[0]?.label);

      const userTeams = await getUserTeamsBySeason(userId, formattedSeasons[0]?.label);
      const formattedUserTeams = userTeams.map(team => ({
        code: team.id,
        label: `${team.club.logo_path ? team.club.logo_path : ''} ${team.club.name} • ${team.name}`,
      }));
      
      setUserTeams(formattedUserTeams);
      setUserTeamSelectedIndex(0);*/

      const userInfo = await getUserInfo(userId, currentSeason);
      await getTeams(currentSeason);

      if(userInfo?.type === "P") {
        await getPlayerClubHistorical(userId)
      } else if(userInfo?.type === "C") {
        await getCoachClubHistorical(userId);
      }

      await getUserSeasonResume(currentSeason, userInfo);

      setLoading(false);
    }
  
    if(isAuthenticated) { fetchData(); }
  }, [userStored]);

  const getUserInfo = async (userId, currentSeason) => {
    let userInfo = {};

    try {
      let user = await API.graphql({
        query: queries.getUser,
        variables: { id: userId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      user = user?.data?.getUser;

      dispatch(setPhotoPath({
        "photoPath": user.photo_path
      }))

      let position;
      if(user.type === "C") {
        position = t('common.coach');
        setUserType("Coaches");

        userInfo = {
          id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          photo_path: user?.photo_path,
          position: user?.position,
          type: user?.type,
          club: {
            id: userStored?.club?.id,
            name: userStored?.club?.names[0]?.name,
            logo_path: userStored?.club?.logo_path,
            team: {
              id: userStored?.club?.team?.id,
              name: userStored?.club?.team?.name,
            }
          }
        }
      } else if (user.type === "P") {
        let club = { team: {} };
        let stats = {};
      
        setUserType("Players");
        const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
      
        const [lastGameResponse, userTeamHistoricalResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/users/v1/users/${userId}/matches`, {
            params: {
              size: 1,
              sortBy: "date",
              sortOrder: "desc",
              rated: true
            },
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }
          }),
      
          API.graphql({
            query: queries.getUserTeamHistorical,
            variables: {
              id: userId,
              teamFilter: {
                season: { eq: currentSeason },
                invite_status: { eq: 1 }
              }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          })
        ]);
      
        const lastGame = lastGameResponse?.data[0];
      
        const mainTeam = lastGame?.home_team?.id ? lastGame.home_team : lastGame?.away_team;

        const userTeamHistoricalItems = userTeamHistoricalResponse.data.getUser.teams.items;
        const teamData = userTeamHistoricalItems.find(item => item.team.id === mainTeam.id);
        const position = teamData ? teamData.position : null;
      
        if (mainTeam?.club) {
          club = {
            id: mainTeam.club.id,
            name: mainTeam.club.names?.[0]?.name,
            logo_path: mainTeam.club.logo_path ?? null,
            team: {
              id: mainTeam.id,
              name: mainTeam.name
            }
          };
        }
      
        if (lastGame?.player_stats) {
          stats = {
            offensive_rating: lastGame.player_stats.offensive_rating,
            defensive_rating: lastGame.player_stats.defensive_rating,
            collective_rating: lastGame.player_stats.collective_rating,
            rating: lastGame.player_stats.rating
          };
        }
      
        userInfo = {
          id: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          photo_path: user?.photo_path,
          position: position,
          type: user?.type,
          club: {},
          stats: stats
        };
      }
      

      setUser(userInfo);

      return userInfo;
    }
    catch (err) {
      //console.log(err)
    }
  }

  const getUserSeasonResume = async (currentSeason, userInfo) => {    
    try {
      const dateNow = moment().format("YYYY-MM-DD")
      let nbMatches;
      let currentStats;

      if(userInfo.type === "C") {
        const teamFilter = {
          season: { eq: currentSeason },
          invite_status: { eq: 1 }
        }
        
        let userTeamHistorical = await API.graphql({
          query: queries.getUserTeamHistorical,
          variables: {
            id: userInfo.id,
            teamFilter: teamFilter
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        userTeamHistorical = userTeamHistorical?.data?.getUser.teams.items;
        const teamIds = userTeamHistorical.map((team) => team.team.id);
        
        const filter = {
          date: { le: dateNow },
          season: { eq: currentSeason },
          "or": teamIds.map((id) => [
            { away_team_id: { eq: id } },
            { home_team_id: { eq: id } }
          ]).flat()
        }
        
        let matches = await API.graphql({
          query: queries.listMatchesResults,
          variables: { filter: filter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        
        matches = matches.data.listMatches.items;

        const { wins, losses, draws } = calculateResults(matches);
        nbMatches = matches.length

        currentStats = {
          matches: nbMatches,
          wins: wins,
          draws: draws,
          losses: losses
        }
      } else if(userInfo.type === "P") {
        const goalFilter = {
          season: { eq: currentSeason }
        };

        const assistFilter = {
          season: { eq: currentSeason }
        };

        const cardFilter = {
          season: { eq: currentSeason }
        };

        const matchFilter = {
          season: { eq: currentSeason },
          start_at: { ge: 0, le: 90 }
        }

        let userStats = await API.graphql({
          query: queries.getPlayerStats,
          variables: {
            id: userInfo.id,
            goalFilter: goalFilter,
            assistFilter: assistFilter,
            cardFilter: cardFilter,
            matchFilter: matchFilter
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        userStats = userStats.data.getUser;
        const nbGoals = userStats.goals.items.length;
        const nbAssists = userStats.assists.items.length;
        nbMatches = userStats.matches.items.length;

        let nbYellowCards = 0;
        let nbRedCards = 0;

        userStats.cards.items.forEach((card) => {
          if (card.type === "Y") {
            nbYellowCards++;
          } else if (card.type === "R") {
            nbRedCards++;
          }
        });

        const playerMatchesFilter = {
          user_id: { eq: userInfo.id },
          season: { eq: currentSeason }
        };

        const matchesFilter = {
          date: { le: dateNow }
        };

        let playerMatches = await API.graphql({
          query: queries.listMatchPlayersScore,
          variables: {
            id: userInfo.id,
            filter: playerMatchesFilter,
            matchTeamFilter: matchesFilter
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        playerMatches = playerMatches.data.listMatchPlayers.items;
        
        const { wins, losses, draws } = calculatePlayerResults(playerMatches);

        currentStats = {
          matches: nbMatches,
          goals: nbGoals,
          assists: nbAssists,
          wins: wins,
          draws: draws,
          losses: losses,
          yellow_cards: nbYellowCards,
          red_cards: nbRedCards
        }
      }
    
      setSeasonResume(currentStats);
    } catch (err) {
      //console.log(err)
    }
  }

  const statsConfig = [
    {
      key: 'matches',
      value: seasonResume.matches,
      title: null,
      icon: <Tooltip title={userType === "Players" ? t('profile.contents.0.labels.1') : t('profile.contents.0.labels.0')}><PitchIcon className="stats-card-title-icon" /></Tooltip>
    },
    {
      key: 'wins',
      value: seasonResume.wins,
      title: t('profile.contents.0.labels.2'),
      icon: null,
    },
    {
      key: 'draws',
      value: seasonResume.draws,
      title: t('profile.contents.0.labels.3'),
      icon: null,
    },
    {
      key: 'losses',
      value: seasonResume.losses,
      title: t('profile.contents.0.labels.4'),
      icon: null,
    },
  ];

  if (userType === "Players") {
    statsConfig.push(
      {
        key: 'goals',
        value: seasonResume.goals,
        title: null,
        icon: 
        <Tooltip title={t('profile.contents.0.labels.5')}><BallIcon className="stats-card-title-icon" /></Tooltip>
      },
      {
        key: 'assists',
        value: seasonResume.assists,
        title: null,
        icon: <Tooltip title={t('profile.contents.0.labels.6')}><AssistIcon className="stats-card-title-icon" /></Tooltip>
      },
      {
        key: 'yellow_cards',
        value: seasonResume.yellow_cards,
        title: t('profile.contents.0.labels.7'),
        icon: <Tooltip title={t('profile.contents.0.labels.7')}><CardIcon className="stats-card-title-icon yellow-card" /></Tooltip>
      },
      {
        key: 'red_cards',
        value: seasonResume.red_cards,
        title: t('profile.contents.0.labels.8'),
        icon: <Tooltip title={t('profile.contents.0.labels.8')}><CardIcon className="stats-card-title-icon red-card" /></Tooltip>
      }
    );
  }

  const getPlayerClubHistorical = async (userId) => {
    try {
      const teamFilter = {
        invite_status: { eq: 1 }
      }
      
      let userTeamHistorical = await API.graphql({
        query: queries.getUserTeamHistorical,
        variables: {
          id: userId,
          teamFilter: teamFilter
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      userTeamHistorical = userTeamHistorical?.data?.getUser.teams.items

      const userTeamHistoricalTable = userTeamHistorical.map((teamItem) => {
        const { season, position } = teamItem;
        const { id, name } = teamItem.team;
        const { names: clubName, id: clubId, logo: clubLogo } = teamItem.team.club;

        return {
          season,
          position,
          team: {
            id,
            name,
            club: {
              name: JSON.parse(clubName[0]).name,
              id: clubId,
              logo: clubLogo
            }
          }
        };
      });

      const teamHistorical = [];
      await Promise.all(userTeamHistoricalTable.map(async(userTeam) => {
        const goalFilter = {
          season: { eq: userTeam?.season },
          team_id: { eq: userTeam?.team?.id }
        };

        const assistFilter = {
          season: { eq: userTeam?.season },
          team_id: { eq: userTeam?.team?.id }
        };

        const cardFilter = {
          season: { eq: userTeam?.season },
          team_id: { eq: userTeam?.team?.id }
        };

        const matchFilter = {
          season: { eq: userTeam?.season },
          start_at: { ge: 0, le: 90 }
        }
      
        try {
          let userStats = await API.graphql({
            query: queries.getPlayerStats,
            variables: { id: userId, goalFilter: goalFilter, assistFilter: assistFilter,
              cardFilter: cardFilter, matchFilter: matchFilter
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          userStats = userStats?.data?.getUser
          const nbGoals = userStats?.goals?.items?.length
          const nbAssists = userStats?.assists?.items?.length
          const nbMatches = userStats?.matches?.items?.length

          let nbYellowCards = 0;
          let nbRedCards = 0;

          userStats?.cards?.items?.forEach((card) => {
            if (card?.type === "Y") {
              nbYellowCards++;
            } else if (card?.type === "R") {
              nbRedCards++;
            }
          });

          teamHistorical.push([userTeam.season, userTeam.team.club.logo, userTeam.team.club.name, userTeam.team.name, nbMatches, nbGoals, nbAssists, nbYellowCards, nbRedCards]);
        } catch (error) {
          // Gérer les erreurs de requête GraphQL
          //console.error('Erreur lors de la récupération des statistiques utilisateur :', error);
        }
      }));
      
      setClubHistorical(teamHistorical);      
    }
    catch (err) {
      //console.log(err)
    }
  }

  const getCoachClubHistorical = async (userId) => {
    try {
      const teamFilter = {
        invite_status: { eq: 1 }
      }

      let userTeamHistorical = await API.graphql({
        query: queries.getUserTeamHistorical,
        variables: { id: userId, teamFilter: teamFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      userTeamHistorical = userTeamHistorical?.data?.getUser.teams.items;

      const userTeamHistoricalTable = userTeamHistorical.map((teamItem) => {
        const { season, position } = teamItem;
        const { id, name } = teamItem.team;
        const { names: clubName, id: clubId, logo: clubLogo } = teamItem.team.club;

        return {
          season,
          position,
          team: {
            id,
            name,
            club: {
              name: JSON.parse(clubName[0]).name,
              id: clubId,
              logo: clubLogo
            }
          }
        };
      });

      const teamHistorical = [];
      const dateNow = moment().format("YYYY-MM-DD")

      /******************** A modifier ************************/
      //Attention: pas la team stored mais la team de la boucle

      await Promise.all(userTeamHistoricalTable.map(async(userTeam) => {
        const filter = {
          date: { le: dateNow },
          season: { eq: userTeam.season },
          or: [
            { away_team_id: { eq: userTeam?.team?.id } },
            { home_team_id: { eq: userTeam?.team?.id } }
          ]
        }

        try {
          let matches = await API.graphql({
            query: queries.listMatchesResults,
            variables: { filter: filter },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          
          matches = matches.data.listMatches.items

          const { wins, losses, draws } = calculateResults(matches, userStored?.club?.team?.id);
          const nbMatches = matches.length

          teamHistorical.push([userTeam.season, userTeam.team.club.logo, userTeam.team.club.name, userTeam.team.name, nbMatches, wins, draws, losses ]);
        } catch (error) {
          // Gérer les erreurs de requête GraphQL
          //console.error('Erreur lors de la récupération des statistiques utilisateur :', error);
        }
      }));
      
      setClubHistorical(teamHistorical);      
    }
    catch (err) {
      //console.log(err)
    }
  }

  const handleDropdownSeason = (data) => {
    setUserSeasonSelected(data.label);
  };

  let headCells;
  if(userType === "Players") {
    headCells = [
      {
        id: "column1",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.0'),
        icon: null,
        maxWidth: 30
      },
      {
        id: "column3",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.1'),
        icon: null,
        maxWidth: 100
      },
      {
        id: "column4",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.2'),
        icon: null,
        maxWidth: 50
      },
      {
        id: "column5",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.3'),
        icon: <PitchIcon className="profile-club-table-icon" />,
        maxWidth: 30
      },
      {
        id: "column6",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.4'),
        icon: <BallIcon className="profile-club-table-icon" />,
        maxWidth: 30
      },
      {
        id: "column7",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.5'),
        icon: <AssistIcon className="profile-club-table-icon" />,
        maxWidth: 30
      },
      {
        id: "column8",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.6'),
        icon:  <CardIcon className="profile-club-table-icon-card yellow-card" />,
        maxWidth: 30
      },
      {
        id: "column9",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.6'),
        icon: <CardIcon className="profile-club-table-icon-card red-card" />,
        maxWidth: 30
      }
    ]
  } else if (userType === "Coaches") {
    headCells = [
      {
        id: "column1",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.0'),
        icon: null,
        maxWidth: 30
      },
      {
        id: "column3",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.1'),
        icon: null,
        maxWidth: 100
      },
      {
        id: "column4",
        align: 'left',
        numeric: false,
        disablePadding: false,
        label: t('profile.contents.1.labels.2'),
        icon: null,
        maxWidth: 50
      },
      {
        id: "column5",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.3'),
        icon: <PitchIcon className="profile-club-table-icon" />,
        maxWidth: 30
      },
      {
        id: "column6",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.8'),
        icon: null,
        maxWidth: 30
      },
      {
        id: "column7",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.9'),
        icon:  null,
        maxWidth: 30
      },
      {
        id: "column8",
        align: 'center',
        numeric: true,
        disablePadding: true,
        label: t('profile.contents.1.labels.10'),
        icon: null,
        maxWidth: 30
      }
    ]
  }

  // Handle Add profile photo
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  return (
    <div className="profile-container">
      <div>
        <div className="profile-user-info">
          <UserCard
            data={user}
            loading={loading}
            onClick={() => setPhotoModalOpen(true)}
            owner={user.id === userId}
          />
        </div>

        <div className="profile-season-summary">
          <CText level="h2" color="light" className="profile-season-summary-title">
            {t('profile.contents.0.title')}
          </CText>

          <div className="season-summary-data-zone">
            {statsConfig.map((stat) => (
              <StatCard
                key={stat.key}
                loading={loading}
                value={stat.value}
                title={stat.title}
                icon={stat.icon}
              />
            ))}
          </div>
        </div>

        <div className="profile-historical">
          <CText level="h1" color="light" fontWeight={700} className="profile-sub-title" label={`${t('profile.contents.1.title')}`} />
          <div className="profile-club-table">
            <CTable headCells={headCells} data={clubHistorical} loading={loading} />
          </div>
        </div>

        {1 === 0 ?
          <div>
            <div className="profile-stats-filters">
              <Dropdown
                onSelect={handleDropdownSeason}
                sx={{ marginRight: 2 }}
                options={userSeasons}
                loading={loading}
              />

              <Dropdown
                onSelect={handleDropdownSeason}
                sx={{ marginRight: 2 }}
                options={userTeams}
                loading={loading}
              />
            </div>
          </div> : null
        }


        <CModal
          open={photoModalOpen}
          onClose={() => setPhotoModalOpen(false)}
          content={<ProfilePhotoModal modalOpenCallback={(data) => setPhotoModalOpen(data)} />}
        >
        </CModal>
      </div>
    </div>
  )
}

export { Profile };