import {makeStyles} from "@material-ui/core/styles"

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  modalSearch: {
    width: '100%'
  },
  modalSearchBarWrapper: {
    paddingLeft: 5,
    paddingRight: 5
  },
  modalSearchResults: {
    marginTop: 20
  },
  modalSearchResult: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    "&&:hover": {
      borderRadius: 10,
      backgroundColor: 'rgba(69, 214, 159, 0.07)',
      cursor: 'pointer'
    }
  },
  modalSearchResultImg: {
    height: 40,
    marginRight: 10
  },
  modalTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalPlayerPhoto: {
    width: 40,
    height: 40,
    borderRadius: 100,
    border: '1px solid',
    marginRight: 10
  },
  modalSelectedPlayerPhoto: {
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: COLOR_LIGHT,
    marginBottom: 15
  },
  modalSelectedPlayer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  modalInfo: {
    marginTop: 30,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  modalValidationBtn: {
    marginTop: 'auto',
    width: 200
  },
  modalCardsZone: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  modalCardZone: {
    all: 'unset',
    cursor: 'pointer',
    width: 30+4,
    height: 30+8,
    borderRadius: 7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  modalCardZoneSelected: {
    backgroundColor: COLOR_PRIMARY
  },
  cardIcon: {
    width: 30,
    height: 30
  },
  selector: {
    width: 150
  },
  icon: {
    width: 23,
    height: 23,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fill: COLOR_LIGHT,
    marginRight: 8
  },
  cardZone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  goalsScorerZone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  goalsAssiterZone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15
  },
  goalsAssiterZone2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
    marginBottom: 10
  },
  modalSubstitutionZone: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  highlightsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  highlightsInfo: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 10,
    listStyleType: 'none',
    [theme.breakpoints.up('xs')]: {
      width: 350,
    },
    [theme.breakpoints.up('sm')]: {
      width: 450,
    },
    [theme.breakpoints.up('md')]: {
      width: 550,
    },
    [theme.breakpoints.up('lg')]: {
      width: 600
    }
  },

  highlightZone: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_DARK,
    borderRadius: 10,
    marginBottom: 10
  },
  highlightZoneHover: {
    cursor: 'pointer',
    "&&:hover": {
      filter: 'brightness(95%)'
    },
  },
  highlightsInfoTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
  },
  highlightsInfoTitle: {
    marginBottom: 20
  },
  highlightsInfoBtnZone: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  substitutionLabelZone: {
    display: 'flex',
    flexDirection: 'column'
  },
  substitutionLineUp: {
    marginBottom: 30
  },
  highligthTimeZone: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginBottom: -20
  },
  goalsTime: {
    padding: 2,
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_DARK,
    width: 30,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  btnContainer: {
    width: 180,
    marginTop: 30
  },
  yellowCard: {
    fill: COLOR_YELLOW
  },
  redCard: {
    fill: COLOR_RED
  },
  switch: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row'
  },
  goalLabel: {
    display: 'flex',
    flexDirection: 'row'
  }
}));