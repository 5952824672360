import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ReactComponent as AssistIcon } from '../../../assets/icons/assist.svg';
import { ReactComponent as BallIcon } from '../../../assets/icons/ball.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/card.svg';
import { ReactComponent as SubstitutionIcon } from '../../../assets/icons/colored/substitution.svg';
import CButton from "../../../components/Button";
import CModal from "../../../components/Modal";
import { CText } from "../../../components/Text";
import { SearchBar } from '../../../components/TextInput';
import ProfilePicture from "../../../components/ProfilePicture/index";
import CSelect from "../../../components/Select";
import CSwitch from "../../../components/Switch/Switch";
import { useStyles } from './Highlights.styles.js';
import { matchTimes, searchPlayers, getTeamPlayers } from './utils.js';
import GoalDescriptionModal from './GoalDescriptionModal';
import OwnGoalDescriptionModal from './OwnGoalDescriptionModal';
import CardModal from './CardModal';
import SubstitutionModal from './SubstitutionModal';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const Highlights = ({goalsNumber, onChangeGoals, goals, onChangeCards, onChangeSubstitutions, cards}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [goalIndex, setGoalIndex] = useState(null);
  const [newGoals, setNewGoals] = useState(Array.from({ length: goalsNumber }, () => ({ scorer: "" })));
  const [cardIndex, setCardIndex] = useState(null);
  const [newCards, setNewCards] = useState([]);
  const [newSubstitutions, setNewSubstitutions] = useState([]);
  const [substitutionIndex, setSubstitutionIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState({value: false});

  useEffect(() => {
    const fetchData = async () => {
      if (goals && goals.length > 0) {
        const updatedGoals = Array.from({ length: goalsNumber }, (_, index) => goals[index] || { scorer: "" });
        setNewGoals(updatedGoals);
        onChangeGoals(updatedGoals);
      }
      if(cards.length > 0) setNewCards(cards);
    }
  
    fetchData()
  }, [])

  const goalDescriptionClick = (index) => {
    setGoalIndex(index);
    setModalOpen({type: 0, value: true});
  }

  const setGoalDescription = async (goal) => {
    setModalOpen({ value: false });
  
    const updateGoals = () => {
      const updatedGoals = [...newGoals];
      updatedGoals[goalIndex] = goal;
      return updatedGoals;
    };
  
    setNewGoals(updateGoals());
    onChangeGoals(updateGoals());
  };

  const addCardClick = () => {
    setModalOpen({type: 1, value: true});
  }

  const updateCardClick = (index) => {
    setCardIndex(index);
    setModalOpen({type: 1, value: true});
  }

  const setCardDescription = (card) => {
    setModalOpen({ value: false });
  
    const updateCards = (prevCards) => {
      const updatedCards = [...prevCards];
      if (cardIndex !== null) {
        updatedCards[cardIndex] = card;
      } else {
        updatedCards.push(card);
      }
      return updatedCards;
    };
  
    setNewCards((prevCards) => updateCards(prevCards));
    setCardIndex(null);
    onChangeCards(updateCards(newCards))
  };

  const ownGoalClick = (e, index) => {
    const goal = {
      own_goal: !newGoals[index].own_goal
    };

    const updateGoals = (index) => {
      const updatedGoals = [...newGoals];
      updatedGoals[index] = goal;
      
      return updatedGoals;
    };

    setNewGoals(updateGoals(index));
    setGoalIndex(index);
    setModalOpen({type: 2, value: !newGoals[index].own_goal});
  }

  const addSubstitutionClick = () => {
    setModalOpen({type: 3, value: true});
  }

  const setSubstitutionDescription = async (substitution) => {
    setModalOpen({ value: false });
  
    const updateSubstitutions = () => {
      const updatedSubstitutions = [...newSubstitutions];


      if (substitutionIndex !== null) {
        updatedSubstitutions[substitutionIndex] = substitution;
      } else {
        updatedSubstitutions.push(substitution);
      }

      return updatedSubstitutions;
    };


    setNewSubstitutions((prevCards) => updateSubstitutions(prevCards));
    setSubstitutionIndex(null);
    onChangeSubstitutions(updateSubstitutions());
  };

  const updateSubstitutionClick = (index) => {
    setSubstitutionIndex(index);
    setModalOpen({type: 3, value: true});
  }

  let MODAL_CONTENT;
  switch (modalOpen.type) {
    case 0:
      MODAL_CONTENT = <GoalDescriptionModal goalDescription={setGoalDescription} goalNumber={goalIndex+1} />
      break;
    case 1:
      MODAL_CONTENT = <CardModal cardDescription={setCardDescription}/>
      break;
    case 2:
      MODAL_CONTENT = <OwnGoalDescriptionModal goalDescription={setGoalDescription} goalNumber={goalIndex+1} />
      break;
    case 3:
      MODAL_CONTENT = <SubstitutionModal description={setSubstitutionDescription} />
      break;
  };

  return (
    <Grid container spacing={2} className={classes.highlightsContainer}>
      <Grid item key={"highlights-goals"}>
        <div className={classes.highlightsInfo}>
          <CText className={classes.highlightsInfoTitle} level="h3" color="primary">{t('after_game.2.labels.0.label')}</CText>
          { newGoals.map((goal, index) => {
            return (
            <div key={`highlights-goals-${index}`} className={`${classes.highlightZone} ${!goal.own_goal ? classes.highlightZoneHover : null}`} onClick={() => !goal.own_goal ? goalDescriptionClick(index) : null}>
              {goal?.own_goal ? (
                <>
                  <div className={classes.highligthTimeZone}>
                    <CText className={classes.goalsTime} level="p3" fontWeight="700">{goal.time}'</CText>
                  </div>
                  <CSwitch
                    checked={goal.own_goal}
                    onChange={(e) => ownGoalClick(e, index)}
                    label={t('after_game.2.labels.3.label')}
                  />
                </>
              ) : goal?.scorer ? (
                <>
                  <div className={classes.highligthTimeZone}>
                    <CText className={classes.goalsTime} level="p3" fontWeight="700">{goal.time}'</CText>
                  </div>
                  <div className={classes.goalsScorerZone}>
                    <span className={classes.icon}>
                      <BallIcon />
                    </span>
                    <CText level="p1" color="light">
                      {goal?.scorer?.first_name} {goal?.scorer?.last_name}
                    </CText>
                  </div>
                  {goal?.assister && (
                    <div className={classes.goalsAssiterZone}>
                      <span className={classes.icon}>
                        <AssistIcon />
                      </span>
                      <CText level="p1" color="light">
                        {goal.assister.first_name} {goal.assister.last_name}
                      </CText>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.goalLabel}>
                  <span className={classes.icon}>
                    <BallIcon />
                  </span>
                  <CText level="p1" color="light">
                    {t('after_game.2.labels.2.label')}{index + 1}
                  </CText>       
                  <CSwitch
                    onChange={(e) => ownGoalClick(e, index)}
                    checked={goal.own_goal}
                    className={classes.switch}
                    label={t('after_game.2.labels.3.label')}
                  />
                </div>
              )}
            </div>
          )})}
        </div>
      </Grid>

      <Grid item key={"highlights-cards"}>
        <div className={classes.highlightsInfo}>
          <div className={classes.highlightsInfoTitleWrapper}>
            <CText className={classes.highlightsInfoTitle} level="h3" color="primary">{t('after_game.2.labels.1.label')} </CText>
            <div className={classes.highlightsInfoBtnZone}>
              <CButton
                label={t('pre_game.buttons.1')}
                height={26}
                size={14}
                type="contained"
                onClick={() => addCardClick()}
              />
            </div>
          </div>
          {newCards.map((card, index) => { return (    
            <div className={`${classes.highlightZone} ${classes.highlightZoneHover}`} onClick={() => updateCardClick(index)} key={index}>
              <div className={classes.highligthTimeZone}>
                <CText className={classes.goalsTime} level="p3" fontWeight="700">{card.time}'</CText>
              </div>

              <div className={classes.cardZone}>
                <span className={classes.icon}>
                  <CardIcon className={[classes.icon, card.type === 'Y' ? classes.yellowCard : classes.redCard].join(" ")} />
                </span>
                <CText level="p1" color="light">
                  {card.player.first_name} {card.player.last_name}
                </CText>
              </div>
            </div>
          )})}
        </div>
      </Grid>

      <Grid item key={"highlights-substitutions"}>
        <div className={classes.highlightsInfo}>
          <div className={classes.highlightsInfoTitleWrapper}>
            <CText className={classes.highlightsInfoTitle} level="h3" color="primary">
              {t('after_game.2.labels.4.label')}
            </CText>
            <div className={classes.highlightsInfoBtnZone}>
              <CButton
                label={t('pre_game.buttons.1')}
                height={26}
                size={14}
                type="contained"
                onClick={() => addSubstitutionClick()}
              />
            </div>
          </div>
          {newSubstitutions.map((substitution, index) => { return (    
            <div className={`${classes.highlightZone} ${classes.highlightZoneHover}`} onClick={() => updateSubstitutionClick(index)} key={index}>
              <div className={classes.highligthTimeZone}>
                <CText className={classes.goalsTime} level="p3" fontWeight="700">{substitution.time}'</CText>
              </div>

              <div className={classes.cardZone}>
                <span className={classes.icon}>
                  <SubstitutionIcon className={[classes.icon].join(" ")} />
                </span>
                <CText level="p1" color="light">
                  <span className={[classes.substitutionLabelZone]}>
                    <span>
                      {substitution.player_in.first_name} {substitution.player_in.last_name}
                      <span style={{marginLeft: 5, fontSize: '0.8em', color: COLOR_PRIMARY}}>({t('after_game.2.labels.5.label')})</span>
                    </span>
                    <span>
                      {substitution.player_out.first_name} {substitution.player_out.last_name}
                      <span style={{marginLeft: 5, fontSize: '0.8em', color: COLOR_PINK}}>({t('after_game.2.labels.6.label')})</span>
                    </span>
                  </span>
                </CText>
              </div>
            </div>
          )})}
        </div>
      </Grid>

      <CModal
        open={modalOpen.value}
        onClose={() => setModalOpen({value: false})}
        content={MODAL_CONTENT}
      >
      </CModal>
    </Grid>
  );
}

export default Highlights;