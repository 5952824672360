import React from 'react';
import MuiFab from '@mui/material/Fab';
import './fab.css';
import Skeleton from '@mui/material/Skeleton';

export const Fab = ({ onClick, children, className, style, ...props }) => {
  return (
    <MuiFab
      onClick={onClick}
      className={`fab ${className || ''}`.trim()}
      sx={{
        ...style,
      }}
      {...props}
    >
      {children}
    </MuiFab>
  );
};

export const FabSkeleton = ({ onClick, children, className, style, ...props }) => {
  return (
    <div className="fab-skeleton-wrapper">
      <Skeleton
        variant="circular"
        animation="wave"
        className="fab-skeleton"
      />
    </div>
  );
};