import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {makeStyles} from "@material-ui/core/styles";

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { CText } from "../Text";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";

const useStyles = makeStyles((theme) => ({
  icon: {
    stroke: COLOR_LIGHT,
    width: 20,
    height: 20
  },
  stepLabel: {
    justifyContent: 'center'
  }
}));

const ColorConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 35/2
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: 10,
    marginLeft: 5,
    marginRight: 5
  },
}));

const CustomStepIconRoot = styled('div')(({ state }) => ({
  backgroundColor: ZONE_BACKGROUND_COLOR,
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: -5,
  ...(state?.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(state?.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

const CustomStepIcon = ({ active, completed, className, icon }) => {
  const classes = useStyles();

  return (
    <CustomStepIconRoot state={{ completed, active }} className={className}>
      {completed ? (
        <CheckIcon className={classes.icon} />
      ) : (
        <CText level="p1" fontWeight="700" color="light" label={icon} />
      )}
    </CustomStepIconRoot>
  );
};

const CStepper = (props) => {
  const classes = useStyles();

  const steps = props.steps;
  const activeStep = props.activeStep;

  return (
    <Stack className={props.className}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorConnector />}>
        {steps.map((step) => (
          <Step key={step.index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              icon={step.index}
            >
              <CText level="p2" fontWeight="700" color="light"  className={classes.stepLabel}>
                {step.label}
              </CText>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};


export { CStepper };