
import React from 'react';
import { CText } from "../../components/Text";
import Skeleton from '@mui/material/Skeleton';
import './StatCard.css';

const StatCard = ({ loading, value, title, icon }) => {
  return (
    <div className="stats-card">
      {loading ? (
        <>
          <Skeleton variant="rectangular" width="80%" height={30} className="stats-card-value" />
          <Skeleton variant="text" width="100%" height={25} className="stats-card-title" />
        </>
      ) : (
        <>
          <CText level="h2" color="light" className="stats-card-value">{value}</CText>
          <div className="stats-card-title">
            {icon ?
              icon : <CText level="p1" color="light" className="stats-card-title">{title}</CText>
            }
          </div>
        </>
      )}
    </div>
  );
};

export default StatCard;