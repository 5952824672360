import React, { useEffect, useState } from 'react';
import './UserCard.css';
import Skeleton from '@mui/material/Skeleton';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import { Storage } from 'aws-amplify';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';

const UserCard = ({ data, loading, onClick, owner, updatePhoto}) => {
  const { t } = useTranslation();

  console.log(data)

  const [isActive, setIsActive] = useState(false);
  const [photo, setPhoto] = useState("");
  const [position, setPosition] = useState({});

  const getPhoto = async (abortSignal) => {
    let userPhoto;
    if(data.photo_path && updatePhoto === true) {
      setPhoto(data.photo_path);
    } else if(data.photo_path) {
      userPhoto = await Storage.get(data.photo_path, { level: 'public' });
      if (!abortSignal.aborted) { setPhoto(userPhoto) };
    } /*else if(userPhotoStored && owner) {
      userPhoto = await Storage.get(userPhotoStored, { level: 'public' });
      if (!abortSignal.aborted) { setPhoto(userPhoto) };
    }*/
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      let position = {};

      await getPhoto(abortController.signal);

      switch (data.position) {
        case 'GK':
          position = {
            short_label: t('positions.0.short_label'),
            label: t('positions.0.label')
          }
          break
        case 'DF':
          position = {
            short_label: t('positions.1.short_label'),
            label: t('positions.1.label')
          }
          break
        case 'MF':
          position = {
            short_label: t('positions.2.short_label'),
            label: t('positions.2.label')
          }
          break
        case 'ST':
          position = {
            short_label: t('positions.3.short_label'),
            label: t('positions.3.label')
          }
          break
      }

      setPosition(position)
      //if (!abortController.signal.aborted) setIsLoaded(true);
    };

    fetchData();

    return () => {
      abortController.abort();
    }
  }, [data]);

  useEffect(() => {
    let timeout;
    if (!loading) {
      timeout = setTimeout(() => {
        setIsActive(true);
      }, 50);
    } else {
      setIsActive(false);
    }

    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <div className={`card-container ${owner ? 'card-clickable' : ''}`} onClick={onClick}>
      {loading ?
        <>
          <div>
            <Skeleton
              variant="rectangular"
              animation="wave"
              className="card-skeleton"
            />
          </div>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 267.3 427.3" 
            preserveAspectRatio="xMidYMid meet"
            className="fifa-card-svg"
          >
            <defs>
              <clipPath id="cardShape" clipPathUnits="objectBoundingBox">
                <path d="
                  M 0.0 0.08
                  Q 0.50 -0.05, 1.0 0.08
                  L 1.0 0.87
                  L 0.50 1.0
                  L 0.0 0.87
                  Z" />
              </clipPath>
            </defs>
          </svg>
        </>
        :
        <div id="card" className={isActive ? 'active' : ''}>
          <div className='card-content'>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 267.3 427.3" 
              preserveAspectRatio="xMidYMid meet"
            >
              <defs>
                <clipPath id="cardShape" clipPathUnits="objectBoundingBox">
                  <path d="
                    M 0.0 0.08
                    Q 0.50 -0.05, 1.0 0.08
                    L 1.0 0.87
                    L 0.50 1.0
                    L 0.0 0.87
                    Z" />
                </clipPath>
              </defs>
            </svg>

            <div id="card-inner">
              {/* Top Card */}
              <div id="card-top">
              {data?.type === "P" ?
                <div className="info">
                  <div className="value">{data?.stats?.rating ? data?.stats?.offensive_rating * 10 : 'N/A'}</div>
                  <div className="position">{position.short_label}</div>
                  <div className="club-logo">
                    <ClubPhoto
                      src={data?.club?.logo_path}
                      size={26}
                    />
                  </div>
                </div>
                : null
              }

              {data?.type === "C" ?
                <div className="info info-coach">
                  <div className="club-logo">
                    <ClubPhoto
                      src={data?.club?.logo_path}
                      size={26}
                    />
                  </div>
                </div>
                : null
              }

                <div className="image">
                  <div className="user-image">
                    {photo ?
                      <img src={photo} alt="Profile Picture" />
                      :
                      <UserIcon className="default-image" />
                    }
                  </div>
                </div>
                {data?.position === null || data?.position === undefined ?
                  <div className="backfont-default">
                    Football<br/>
                    Connexion
                  </div>
                  :
                  <div className="backfont">{position.label}</div>
                }
              </div>

              {/* Bottom Card */}
              <div id="card-bottom">
                <div className="name">
                  <Tooltip title={`${data?.first_name} ${data?.last_name}`}>
                    <div>
                      {(() => {
                        const fullName = `${data?.first_name.charAt(0).toUpperCase()}.${data?.last_name}`;
                        return fullName.length > 15 
                          ? fullName.slice(0, 12) + '...'
                          : fullName;
                      })()}
                    </div>
                  </Tooltip>
                </div>
                <div className="club">
                  {data?.club?.name 
                    ? `${data?.club?.name} • ${data?.club?.team?.name}` 
                    : t("user_card.no_team")}
                </div>

                <div className="bottom-info">
                  {data?.type === "P" ?
                    <>
                      <div className="stat attack">
                        <p className="stat-value">{data?.stats?.rating ? data?.stats?.offensive_rating * 10 : 'N/A'}</p>
                        <p className="stat-label">{t('user_card.attack.short_label')}</p>
                      </div>
                      <div className="stat defense">
                        <p className="stat-value">{data?.stats?.rating ? data?.stats?.defensive_rating * 10 : 'N/A'}</p>
                        <p className="stat-label">{t('user_card.defense.short_label')}</p>
                      </div>
                      <div className="stat collective">
                        <p className="stat-value">{data?.stats?.rating ? data?.stats?.collective_rating * 10 : 'N/A'}</p>
                        <p className="stat-label">{t('user_card.collective.short_label')}</p>
                      </div>
                    </>
                    :
                    <p className="coach-position">{t('common.coach')}</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default UserCard;