import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import userImage from '../../assets/images/user.svg';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

const ProfilePicture = ({ className, onClick, src, updatePhoto, onLoad, owner, loading }) => {  
  const useStyles = makeStyles((theme) => ({
    photoZone: {
      backgroundColor: ZONE_BACKGROUND_COLOR,
      borderRadius: 18,
      listStyleType: 'none',
      borderRadius: '100%',
      borderColor: COLOR_PRIMARY,
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center'
    },
    photoIcon: {
      height: '95%'
    },
    photo: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      overflow: 'hidden',
      margin: "auto",
      objectFit: 'cover'
    },
    photoZoneSkeleton: {
      backgroundColor: 'none !important',
      border: '0px solid !important',
    },
  }));

  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [photo, setPhoto] = useState("");

  const isMountedRef = useRef(null);

  // Get User stored
  const userStored = useSelector((state) => state?.user);
  const userPhotoStored = userStored.photo_path;

  const getPhoto = async (abortSignal) => {
    let userPhoto;
    if(src && updatePhoto === true) {
      setPhoto(src);
    } else if(src) {
      userPhoto = await Storage.get(src, { level: 'public' });
      if (!abortSignal.aborted) setPhoto(userPhoto);
    } else if(userPhotoStored && owner) {
      userPhoto = await Storage.get(userPhotoStored, { level: 'public' });
      if (!abortSignal.aborted) setPhoto(userPhoto);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      await getPhoto(abortController.signal);
      if (!abortController.signal.aborted) setIsLoaded(true);
    };

    fetchData();

    return () => {
      abortController.abort();
    }
  }, [userStored, src, updatePhoto, owner]);
  
  return(
    <>
    { (loading === undefined || loading === false) ?
      <div className={[classes.photoZone, className].join(' ')} onClick={onClick}>
        {isLoaded ? (
          photo ?
            <img src={photo} className={classes.photo} onLoad={onLoad} />
            :
            <img src={userImage} className={classes.photoIcon} onLoad={onLoad} />
          ) : null
        }
      </div>
      :
      <div className={[classes.photoZoneSkeleton, className].join(' ')} onClick={onClick}>
        <Skeleton variant="circular" width='100%' height='100%' />
      </div>
    }
    </>
  )
}

export default ProfilePicture;