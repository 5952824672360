import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { API } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import MatchCard from '../../components/Cards/MatchCard';
import Highlights from './Highlights/Highlights';
import Evaluation from './Evaluation';
import Score from './Score';
import GameLineUp from './GameLineUp';
import GoalDescription from './GoalDescription';
import { CStepper } from '../../components/Stepper/index';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import NoGame from "../../components/Cards/NoGame";
import { getCurrentSeason } from "../../utils/season";
import { useParams } from 'react-router-dom';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const COLOR_DARK = "#242834";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

const AfterGame = (props) => {
  const { t } = useTranslation();
  const userStored = useSelector(state => state?.user);
  const { matchId } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [contentStep, setContentStep] = useState(0);

  const [score, setScore] = useState();
  const [lineUp, setLineUp] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [game, setGame] = useState([]);
  const [currentSeason, setCurrentSeason] = useState("");
  const [playersList, setPlayersList] = useState([]);
  const [captain, setCaptain] = useState("");
  const [goals, setGoals] = useState([]);
  const [cards, setCards] = useState([]);
  const [substitutions, setSubstitutions] = useState([]);
  
  useEffect(() => {  
    const fetchData = async () => {
      const currentSeason = await getCurrentSeason();
      setCurrentSeason(currentSeason);
      const game = await getGame(currentSeason);

      if(game?.stats_submitted) {
        navigate(`/calendar_results`)
      }

      setLoaded(true)
    }
  
    fetchData()
  }, [])

  const getGame = async() => {
    try {
      let getMatchResp = await API.graphql({
        query: queries.getMatch,
        variables: { id: matchId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      getMatchResp = getMatchResp.data.getMatch;

      setGame(getMatchResp);

      return getMatchResp;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      flexGrow: 1
    },
    titleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'start',
      width: '100%'
    },
    title: {
      marginBottom: 15
    },
    teamName: {
      marginLeft: 5,
      marginRight: 5
    },
    contentZone: {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    stepper: {
      width: '100%',
      boxSizing: 'border-box',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 480,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 600,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 650
      },
      marginBottom: 50
    },
    btnContainer: {
      width: 180,
      marginTop: 30,
      textAlign:'center'
    },
    btn: {
      width: 180
    },
    stepTitleZone: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: contentStep > 0 ? -29 : 0,
      [theme.breakpoints.up('xs')]: {
        width: 350+60,
      },
      [theme.breakpoints.up('sm')]: {
        width: 450+60,
      },
      [theme.breakpoints.up('md')]: {
        width: 550+60,
      },
      [theme.breakpoints.up('lg')]: {
        width: 600+60
      }
    },
    stepTitleWrapper: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    stepTitle: {
      marginBottom: 30
    },
    stepTitleIcon: {
      stroke: COLOR_LIGHT,
      fill: COLOR_LIGHT,
      width: 22,
      height: 22,
      backgroundColor: ZONE_BACKGROUND_COLOR,
      padding: 7,
      borderRadius: '50%',
      cursor: 'pointer',
      "&:hover": {
        opacity: '0.8'
      }
    },
    matchCard: {
      marginBottom: 25,
      "&&:hover": {
        filter: 'none !important',
        boxShadow: 'none'
      }
    },
    noTeamsInfo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  }));
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNext = () => {
    setTimeout(function() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setContentStep((prevContentStep) => prevContentStep + 1);
    }, 100);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setContentStep((prevContentStep) => prevContentStep - 1);
  };

  const updatePlayersAndStats = async (allPlayers, matchPlayers, captainId, currentSeason, gameId) => {
    const playersToUpdate = [];
    let currentCaptain = null;
  
    await Promise.all(allPlayers.map(async (player, index) => {
      if (player.player && player.player.id) {
        const existingPlayer = matchPlayers.items.find(matchPlayer => matchPlayer.user.id === player.player.id);
  
        const matchPlayerInfo = {
          start_at: player?.start_at,
          user_id: player?.player?.id,
          position: player?.position,
          offensive_rating: player?.evaluation?.offensive_rating || 0,
          defensive_rating: player?.evaluation?.defensive_rating || 0,
          collective_rating: player?.evaluation?.collective_rating || 0,
          rating: ((player?.evaluation?.offensive_rating || 0) + (player?.evaluation?.defensive_rating || 0) + (player?.evaluation?.collective_rating || 0)) / 3,
          attempted_passes: player?.evaluation?.attempted_passes,
          successful_passes: player?.evaluation?.successful_passes,
          attempted_dribbling: player?.evaluation?.attempted_dribbling,
          successful_dribbling: player?.evaluation?.successful_dribbling,
          attempted_shots: player?.evaluation?.attempted_shots,
          successful_shots: player?.evaluation?.successful_shots,
          attempted_tackles: player?.evaluation?.attempted_tackles,
          successful_tackles: player?.evaluation?.successful_tackles,
        };
  
        if (existingPlayer) {

          let hasDifference = false;
          if ((player.player.id === captainId) !== existingPlayer.captain || player.position !== existingPlayer.position) {
            hasDifference = true;
          }
  
          if (hasDifference || matchPlayerInfo) {
            matchPlayerInfo.id = existingPlayer.id;
            playersToUpdate.push(matchPlayerInfo);
          }
  
          if (existingPlayer.captain) {
            currentCaptain = existingPlayer;
          }
        } else {
          matchPlayerInfo.match_id = gameId;
          matchPlayerInfo.matchPlayersId = gameId;
          matchPlayerInfo.userMatchesId = player?.player?.id;
          matchPlayerInfo.user_id = player?.player?.id;
          matchPlayerInfo.season = currentSeason;
          playersToUpdate.push(matchPlayerInfo);
        }
      }
    }));
  
    if (!captainId && currentCaptain) {
      currentCaptain.captain = false;
      await API.graphql({
        query: mutations.updateMatchPlayer,
        variables: {
          input: {
            id: currentCaptain.id,
            captain: false
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }
  
    await Promise.all(playersToUpdate.map(async (player) => {
      if (player?.user_id === captainId) {
        player.captain = true;
      } else {
        player.captain = false;
      }
  
      if (player.id) {
        await API.graphql({
          query: mutations.updateMatchPlayer,
          variables: { input: player },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } else {
        await API.graphql({
          query: mutations.createMatchPlayer,
          variables: { input: player },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }
    }));
  };  

  const updateCards = async (matchPlayers) => {
    await Promise.all(cards.map(async (card) => {
      const matchingPlayer = matchPlayers.items.find((matchPlayer) => matchPlayer.user.id === card.player.id);
  
      if (matchingPlayer) {
        const cardInfo = {
          type: card.type,
          time: card.time,
          user_id: card.player.id,
          userCardsId: card.player.id,
          match_id: game.id,
          matchCardsId: game.id,
          matchPlayerCardsId: matchingPlayer.id,
          match_player_id: matchingPlayer.id,
          season: currentSeason,
          team_id: userStored?.club.team.id,
          teamCardsId: userStored?.club.team.id
        };
  
        await API.graphql({
          query: mutations.createCard,
          variables: { input: cardInfo },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }
    }));
  };
  
  const updateGoalsAndAssists = async (matchPlayers) => {
    await Promise.all(goals.map(async (goal) => {
      let createdGoalId;

      if (goal.own_goal === true) {
        const goalInfo = {
          match_id: game.id,
          matchGoalsId: game.id,
          time: goal.time,
          season: currentSeason,
          team_id: userStored?.club.team.id,
          teamGoalsId: userStored?.club.team.id,
          own_goal: true
        };
  
        const createGoalResponse = await API.graphql({
          query: mutations.createGoal,
          variables: { input: goalInfo },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
  
        createdGoalId = createGoalResponse.data.createGoal.id;
  
      } else {
        let scorerMatchingPlayer, assisterMatchingPlayer;
  
        if (goal?.scorer?.id) {
          scorerMatchingPlayer = matchPlayers.items.find((matchPlayer) => matchPlayer.user.id === goal.scorer.id);
        }
  
        if (goal?.assister?.id) {
          assisterMatchingPlayer = matchPlayers.items.find((matchPlayer) => matchPlayer.user.id === goal.assister.id);
        }
  
        if (goal?.scorer?.id) {
          let goalInfo = {
            match_id: game.id,
            matchGoalsId: game.id,
            time: goal.time,
            user_id: goal.scorer.id,
            userGoalsId: goal.scorer.id,
            season: currentSeason,
            team_id: userStored?.club.team.id,
            teamGoalsId: userStored?.club.team.id,
            own_goal: false
          };

          if(scorerMatchingPlayer) {
            goalInfo.match_player_id = scorerMatchingPlayer.id;
            goalInfo.matchPlayerGoalsId = scorerMatchingPlayer.id;
          }
  
          const createGoalResponse = await API.graphql({
            query: mutations.createGoal,
            variables: { input: goalInfo },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
  
          createdGoalId = createGoalResponse.data.createGoal.id;
        }
  
        if (goal?.assister?.id) {
          let assistInfo = {
            goal_id: createdGoalId,
            goalAssistsId: createdGoalId,
            match_id: game.id,
            matchAssistsId: game.id,
            user_id: goal.assister.id,
            userAssistsId: goal.assister.id,
            season: currentSeason,
            team_id: userStored?.club.team.id,
            teamAssistsId: userStored?.club.team.id
          };

          if(assisterMatchingPlayer) {
            assistInfo.match_player_id = assisterMatchingPlayer.id;
            assistInfo.matchPlayerAssistsId = assisterMatchingPlayer.id;
          }
  
          await API.graphql({
            query: mutations.createAssist,
            variables: { input: assistInfo },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }
      }
    }));
  };

  const updateSubstitutions = async (matchPlayers, substitutions) => {
    await Promise.all(substitutions.map(async (substitution) => {
      const playerOut = matchPlayers.items.find((matchPlayer) => matchPlayer.user.id === substitution.player_out.id);
      
      if (playerOut) {
        await API.graphql({
          query: mutations.updateMatchPlayer,
          variables: {
            input: {
              id: playerOut.id,
              left_at: substitution.time
            }
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }

      const playerIn = matchPlayers.items.find((matchPlayer) => matchPlayer.user.id === substitution.player_in.id);
      
      if (playerIn) {
        await API.graphql({
          query: mutations.updateMatchPlayer,
          variables: {
            input: {
              id: playerIn.id,
              start_at: substitution.time
            }
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } else {
        const newPlayerIn = {
          match_id: game.id,
          matchPlayersId: game.id,
          user_id: substitution.player_in.id,
          userMatchesId: substitution.player_in.id,
          start_at: substitution.time,
          season: currentSeason
        };
        
        await API.graphql({
          query: mutations.createMatchPlayer,
          variables: { input: newPlayerIn },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }
      
      const substitutionData = {
        match_id: game.id,
        player_in_id: substitution.player_in.id,
        player_out_id: substitution.player_out.id,
        time: substitution.time
      };
  
      await API.graphql({
        query: mutations.createSubstitution,
        variables: { input: substitutionData },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }));
  };  

  const handleSave = async () => {
    let matchInput = {
      id: game?.id,
      home_team_goals: score?.home_team,
      away_team_goals: score?.away_team,
      stats_submitted: true
    };
  
    if (game?.home_team?.id) {
      matchInput.home_team_formation = lineUp.formation;
    } else {
      matchInput.away_team_formation = lineUp.formation;
    }
  
    await API.graphql({
      query: mutations.updateMatch,
      variables: { input: matchInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    const allPlayers = lineUp.line_up.concat(lineUp.bench_players);
  
    const matchPlayerFilter = {
      match_id: { eq: game.id }
    };
  
    let matchPlayers = await API.graphql({
      query: queries.listMatchPlayers,
      variables: { filter: matchPlayerFilter },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    matchPlayers = matchPlayers.data.listMatchPlayers;
  
    await updatePlayersAndStats(allPlayers, matchPlayers, captain, currentSeason, game.id);
    
    try {
      await updateCards(matchPlayers);
    } catch (error) {
    };
  
    try {
      await updateGoalsAndAssists(matchPlayers);
    } catch (error) {
    };
  
    try {
      await updateSubstitutions(matchPlayers, substitutions);
    } catch (error) {
    };
  
    navigate(`/matches/${game.id}`);
  };
  

  const newScore = (data) => {
    setGame((prevGame) => ({
      ...prevGame,
      home_team_goals: data.home_team,
      away_team_goals: data.away_team,
    }));
    
    setScore(data);
    contents[2].content = <Highlights goalsNumber={game?.home_team?.id ? score?.home_team : score?.away_team} />;
  }

  const newLineUp = (data) => { setLineUp(data) };
  const newPlayersList = (data) => { setPlayersList(data) };
  const newCaptain = (data) => { setCaptain(data) };
  const newGoals = (data) => { setGoals(data) };
  const newCards = (data) => { setCards(data) };
  const newSubstitutions = (data) => { setSubstitutions(data) };

  const contents = [
    {
      index: 1,
      label: `${t('after_game.0.title')}`,
      content:
        <Score
          game={game}
          onChangeScore={newScore}
          score={score ? `${score?.home_team}${score?.away_team}` : ""}
        />
    },
    {
      index: 2,
      label: `${t('after_game.1.title')}`,
      content:
        <GameLineUp
          currentSeason={currentSeason}
          game={game}
          onChangeLineUp={newLineUp}
          lineUp={lineUp}
          onChangePlayersList={newPlayersList}
          onChangeCaptain={newCaptain}
          playersList={playersList}
          captain={captain}
        />
    },
    {
      index: 3,
      label: `${t('after_game.2.title')}`,
      content:
        <Highlights
          goalsNumber={game?.home_team?.id ? score?.home_team : score?.away_team }
          onChangeGoals={newGoals}
          goals={goals}
          onChangeCards={newCards}
          onChangeSubstitutions={newSubstitutions}
          cards={cards}
        />
    },
    {
      index: 4,
      label: `${t('after_game.3.title')}`,
      content:
        <Evaluation
          lineUp={lineUp}
          onChangeLineUp={newLineUp}
          goals={goals}
          cards={cards}
          score={{
            "myTeam": game?.home_team?.id ? score?.home_team : score?.away_team,
            "opponent": game?.home_team?.id ? score?.away_team : score?.home_team
          }}
        />
    }
  ];

  let CONTENT;
  if(loaded === true) {
    if(game) {
      CONTENT = 
      <div className={classes.contentZone}>
        <div className={classes.titleZone}>
          <CText className={classes.title} level="h1" color="light">{t('common.after_game')}</CText>
        </div>
        <MatchCard data={game} className={classes.matchCard} />
        <CStepper className={classes.stepper} steps={contents} activeStep={activeStep} />
        <div className={classes.stepTitleZone}>
          { contentStep > 0 ? <LeftArrowIcon className={classes.stepTitleIcon} onClick={() => handlePrevious()} /> : null }
          <div className={classes.stepTitleWrapper}>
            <CText className={classes.stepTitle} level="h1" color="primary">{t(`after_game.${contentStep}.label`)}</CText>
          </div>
        </div>

        {contents[contentStep].content}

        <div className={classes.btnContainer}>
          {activeStep < contents.length-1 ?
            <CButton
              className={classes.btn}
              label={t('button.next')}
              type="contained"
              color={COLOR_PRIMARY}
              height={40}
              size={14}
              onClick={() => handleNext()}
            />
            :
            <CButton
              className={classes.btn}
              label={t('button.save')}
              type="contained"
              color={COLOR_PRIMARY}
              height={40}
              size={14}
              loader={true}
              onClick={() => handleSave()}
            />
          }
        </div>
      </div>
    } else {
      CONTENT =
      <div className={classes.noTeamsInfo}>
        <NoGame type="evalGame" />
      </div>
    }
  } else {
    CONTENT = <div></div>
  }


  let contentModal = <GoalDescription />

  return (
    <div className={classes.container}>
      {CONTENT}

      <CModal
        open={false}
        //onClose={() => setModalPlayerListOpen(false)}
        content={contentModal}
      >
      </CModal>
    </div>
  )
}
  
export default AfterGame;